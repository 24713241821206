<template>
    <div>
        报警历史
    </div>
</template>

<script>
export default {
    name: 'alarmHistory',
    components: {},
    data() {
        return {}
    },
    mounted(){

    },
    methods: {}
}
</script>

<style lang="scss" scoped>

</style>